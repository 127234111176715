.header {
  margin: auto;
  width: var(--page-width);
  max-width: 990px;
  position: relative;
  z-index: 5;
}

.header__nav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.header__logo {
  margin-right: 200px;
  font-size: 2.5em;
  margin: 0;
  font-family: 'Josefin Sans', 'Noto Sans Display', Sans-serif, Arial, Helvetica;
  position: relative;
}

.header__box {
  position: absolute;
  right: 0;
  display: flex;
  gap: 10px;
}

.header__btn {
  transition: all 0.3s;
  padding: 3px 5px;
  color: var(--main-grey);
  border: 1px solid var(--main-grey);
  font-size: 1.2em;
  text-decoration: none;
  transition: all 0.3s;
}

.header__btn:hover {
  background-color: var(--main-grey);
  color: var(--background-color);
}

/* .header__logo:after {
  display: block;
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  height: 1px;
  background: #666561;
  left: 13px;
  bottom: 2px;
} */

.header__logo span {
  font-size: 27px;
  font-weight: 400;
  margin-left: 10px;
  display: initial;
}

/* .header__contacts {
  font-size: 18px;
  text-decoration:underline 1px;
  text-decoration-color: transparent;
  transition: all 0.3s;
}

.header__contacts:hover {
  text-decoration-color: #fff;
} */

.header__ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 70px;
  font-size: 18px;
}

.header__picture {
  width: 50px;
  position: absolute;
  top: 58px;
  left: 102px;
  z-index: 0;
}

.header__picture_small {
  width: 41px;
  left: 148px;
}

.header__picture_last {
  left: 600px;
  top: 30px;
}

@media (max-width: 600px) {
  .header__logo span {
    display: none;
  }
}

.promo {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -50px;
  position: relative;
  padding: 0 5px;
}

.promo__container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.promo__heading {
  color: var(--main-grey);
  font-size: 5em;
  margin: 0;
  letter-spacing: 7px;
  font-weight: 400;
  background-color: var(--background-color);
  z-index: 1;
  text-align: center;
}

.promo__name {
  font-size: 2em;
  margin: 0;
  background-color: var(--main-grey);
  color: var(--background-color);
  padding: 2px 29px;
  display: inline-flex;
  align-self: flex-start;
}

.promo__description {
  margin: 10px 0 0;
  align-self: flex-end;
  font-size: 1.3em;
  max-width: 500px;
  text-align: end;
}

.promo__circle-wrapper {
  display: inline-block;
  position: absolute;
  transition: opacity 0.3s;
  cursor: pointer;
  right: -42px;
  top: -25px;
}

.promo__circle-wrapper:hover {
  opacity: 0.7;
}

.promo__circle {
  animation: rotate 8s infinite linear;
  width: 145px;
  transition: all 0.3s;
}

.promo__circle-text {
  font-size: 8px;
  font-weight: 400;
  transition: all 0.3s;
}

.promo__circle-title {
  position: absolute;
  transform: translate(-50%, -50%) rotate(15deg);
  left: 50%;
  top: 50%;
  font-size: 2.6em;
  font-family: 'Archivo Black', 'Raleway', Sans-serif, Arial;
  color: #dfd7a3;
  transition: var(--standart-transition);
}

@media (max-width: 997px) {
  .promo__circle-wrapper {
    right: -24px;
    z-index: 1;
  }
}

@media (max-width: 768px) {
  .promo__circle-wrapper {
  }

  .promo__heading {
    font-size: 4em;
  }

  .promo__name {
    font-size: 1.7em;
  }
}

@media (max-width: 570px) {
  .promo__circle-wrapper {
    right: -24px;
    z-index: 0;
  }
  .promo__heading {
    font-size: 2.5em;
  }

  .promo__name {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  .promo__description {
    font-size: 1.2em;
  }

  .promo__circle-wrapper {
    right: auto;
    left: auto;
    z-index: 0;
    bottom: -150px;
    top: auto;
  }

  .promo__circle {
    width: 135px;
  }

  .header {
    width: 75%;
  }

  .header__nav {
    justify-content: space-between;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

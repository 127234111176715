html {
  scroll-behavior: smooth;
}

:root {
  --background-color: #eee9db; /* #F9F9F2 */ /* eee9db */
  --main-grey: #666561;
  --page-width: 98%;
  --max-width: 1200px;
  --dark-grey: #4b4b4b;
}

.page {
  min-height: 100vh;
  /* background: #282828; */
  background-color: var(--background-color);
  font-family: 'Noto Sans Display', Sans-serif, Arial, Helvetica;
  font-weight: 300;
  padding-top: 15px;
  font-size: 16px;
  color: var(--main-grey);
  overflow: hidden;
  animation: fade-in 0.8s;
}

.page-limits {
  margin: auto;
  width: var(--page-width);
  max-width: var(--max-width);
  animation: opacity 1.5s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

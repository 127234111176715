.card {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.card__img-link {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 950px;
  box-shadow: 0px 0px 34px 5px #39393799;
}

.card__img {
  width: 100%;
  position: relative;
  object-fit: cover;
  object-position: left;
}

.card__overlay {
  position: absolute;
  background-color: #363430a1;
  opacity: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: 0.3s all linear;
}

.card__overlay:hover {
  opacity: 0.6;
}

.card__data {
  color: var(--background-color);
  width: 55%;
  margin-left: -25%;
  z-index: 1;
  background-color: var(--dark-grey);
  opacity: 0.95;
  padding: 10px;
}

.card:nth-of-type(2n) .card__data {
  text-align: right;
  order: -1;
  margin-left: 0;
  margin-right: -25%;
}

.card:nth-of-type(2n) .card__data .card__ul {
  justify-content: flex-end;
}

.card__heading {
  font-weight: 200;
  margin: 0;
  font-size: 1.7em;
  color: #ffcb5e;
  letter-spacing: 2px;
}

.card__description {
  font-size: 1.3em;
  font-weight: 200;
  line-height: 1.3em;
}

.card__ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 10px;
}

.car__link-box {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.card:nth-of-type(2n) .card__data .car__link-box {
  justify-content: flex-end;
}

.card__link svg {
  width: 100%;
  display: block;
  opacity: 0.6;
  transition: 0.3s all;
  width: 25px;
  height: 25px;
  color: var(--background-color);
}

.card__link svg:hover {
  opacity: 1;
}

@media (max-width: 900px) {
  .card__description {
    font-size: 1.1em;
  }

  .card__heading {
    font-size: 1.5em;
  }
}

@media (max-width: 780px) {
  .card {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .card__data {
    width: 80%;
    margin: 0;
    background-color: transparent;
    padding: 5px;
  }

  .card:nth-of-type(2n) .card__data {
    text-align: start;
    order: 0;
    margin: 0;
  }

  .card:nth-of-type(2n) .card__data .card__ul {
    justify-content: start;
  }

  .card:nth-of-type(2n) .card__data .car__link-box {
    justify-content: flex-start;
  }
}

@media (max-width: 600px) {
  .card__data {
    width: 95%;
  }
}

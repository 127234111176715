/* .main {
  margin: auto;
  width: 95%;
  max-width: 1100px;
} */

.main {
  background-color: var(--dark-grey); /* #4c493d */
  color: var(--background-color);
  padding: 30px 0;
}

.main__heading {
  font-size: 2.5em;
  margin: 50px 0 50px;
  font-weight: 200;
  text-align: center;
  letter-spacing: 5px;
}

.main__card-list {
  display: flex;
  flex-direction: column;
  gap: 6em;
  width: var(--page-width);
  max-width: var(--max-width);
  margin: 70px auto;
}

.main__techs > ul {
  font-weight: 200;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  gap: 30px;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  letter-spacing: 1px;
  width: var(--page-width);
  max-width: var(--max-width);
  margin: auto;
}

@media (max-width: 780px) {
  .main__card-list {
    gap: 5em;
  }
}

@media (max-width: 600px) {
  .main__heading {
    font-size: 2.1em;
  }

  .main__techs > ul {
    font-size: 1.2em;
  }
}
